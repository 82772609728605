export const queryFragmentBlocks = {
  blocks: {
    populate: {
      fields: [
        'i18n_string_en',
        'i18n_string_al',

        'i18n_richtext_en',
        'i18n_richtext_al',

        'view_option',
      ],

      images: {
        populate: true
      },
    }
  }
}

export const queryFragmentBlocksText = {
  on: {
    'blocks.text': {
      filters: {
        i18n_richtext_en: {
          $notNull: true
        }
      }
    }
  }
}


export const queryFragmentBlockImages = {
  on: {
    'blocks.images': {
      filters: {
        images: {
          id: {
            $notNull: true
          }
        }
      },
      populate: {
        images: true
      }
    }
  }
}
